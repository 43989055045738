@import '/src/globalStyles';

// Components classes
.welcome {
  text-align: center;
  height: 105vh;

  .imgBranding {
    width: 80%;
    margin: 0.5rem auto;
  }

  &.wpage {
    background: $red-strong  !important;
  }

  .loader {
    display: grid;
    text-align: center;
    position: relative;
    top: 0vh;

    div {
      margin: 0 auto;
    }
  }

  .title {
    color: #fff;
    font-size: 2rem;
    margin: 5rem 0 0.2rem 0;
  }

  .description {
    color: #fff;
    margin: 1rem 0 4rem 0;
    padding: 0 2rem;
  }

  .btnLogin {
    font-family: 'Montserrat', sans-serif;
    text-transform: inherit;
    padding: 0.8rem;
    width: 80%;
    border-radius: 20px;
    margin: 2.5rem 0 1.5rem 0 !important;

    &.wpage {
      background: #bdbdbd;
      color: $black;
    }

    &.lpage {
      background: $red-custom;
      color: #fff;
    }

    &.fullw {
      width: 100%;
    }

    &:hover {
      background: $red-custom;
    }
  }

  .btnRegister {
    font-family: 'Montserrat', sans-serif;
    text-transform: inherit;
    padding: 0.8rem;
    width: 80%;
    border-radius: 20px;
    background: $red-custom;

    &:hover {
      background: $red-light;
    }
  }
}

.formLogin {
  text-align: left;
  padding: 3rem 3rem 0 3rem !important;

  .btnBlueRounded {
    background: $blue-dark;
    margin: 3rem 0 1.5rem 0;
    width: 100%;
    border-radius: 20px;
  }

  a {
    color: $red-custom;
  }
}

.errorM {
  color: $red-custom;
}

.flags {
  width: 30%;
  display: flex;
  float: right;
  position: relative;
  bottom: 20px;
  left: 30px;
}

@media only screen and (max-width: 1440px) and (min-width: 767px) {
  .imgBranding {
    width: 40% !important;
    margin: 0.5rem auto;
  }

  .flags {
    width: 20%;
  }

  .welcome {
    height: auto !important;
    padding: 1rem;
    height: 105vh !important;

    &.wpage {
      padding: 4rem 0;
    }

    .imgBranding {
      width: 20%;
      margin: 0.5rem auto;
    }
  }

  .pt15 {
    padding: 2rem 3rem;
  }

  .formLogin {
    padding: 2rem 20rem !important;

    h2,
    p {
      text-align: center;
    }

    form {
      padding: 4rem 5rem !important;
      width: 80% !important;

      .btnLogin.lpage {
        width: 50%;
        margin: 2.5rem auto !important;
      }
    }
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1441px) {
  .welcome {
    height: auto !important;
    padding: 1rem;
    height: 105vh !important;

    &.wpage {
      padding: 4rem 0;
    }

    .imgBranding {
      width: 20%;
      margin: 0.5rem auto;
    }
  }

  .pt15 {
    padding: 2rem 3rem;
  }

  .formLogin {
    padding: 2rem 20rem !important;

    h2,
    p {
      text-align: center;
    }

    form {
      padding: 4rem 5rem !important;
      width: 80% !important;

      .btnLogin.lpage {
        width: 50%;
        margin: 2.5rem auto !important;
      }
    }
  }

  .flags {
    bottom: -10px !important;
    left: 90px !important;
    width: 13%;
  }
}