$red-strong: #591919;
$red-light: #8c2323;
$red-light-2: #732222;
$red-custom: #a54131;
$red-extra-light: #bf8e8e;
$white: #f2f2f2;
$black: #090d0f;
$gray: #565656;
$blue-dark: #141825;
$green-light: #4caf50;
$green-dark: #1b5e20;
$yellow: #ffc107;
$orange: #f57c00;
$blue: #01579b;
$purple: #4527a0;
$red-confirmed: #c62828;
$blue-cotizado: #001e3c;
$blue-map: #263238;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(224, 224, 224) !important;
  height: 100vh !important;
}

// Global classes
.pt15 {
  padding-top: 8vh !important;
}

p {
  font-family: 'Montserrat', sans-serif;
}
